import React from "react"
import Particles from "react-particles-js"
import Layout from "../components/layout"
import About from "../components/about"
import Links from "../components/links"

import particleOptions from "../assets/particles.json"

export default () => {
  return (
    <Layout>
      <Particles className="particles" params={particleOptions} />
      <div className="outerContainer">
        <div className="mainContainer">
          <About />
          <Links />
        </div>
      </div>
    </Layout>
  )
}
