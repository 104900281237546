import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { FaGithub, FaLinkedin, FaEnvelopeOpen, FaFilePdf } from "react-icons/fa"

export default () => {
  const icons = [FaGithub, FaLinkedin, FaEnvelopeOpen, FaFilePdf]
  const data = useStaticQuery(graphql`
    query {
      minimalistConfig(id: { eq: "site-config" }) {
        socialList {
          url
          ariaLabel
        }
      }
    }
  `)

  const renderIcon = (index) => {
    return React.createElement(icons[index], {})
  }

  return (
    <>
      <div className="links">
        {data.minimalistConfig.socialList.map((social, index) => (
          <a
            href={social.url}
            rel="noopener noreferrer"
            target="_blank"
            aria-label={social.ariaLabel}
            key={index}
          >
            {renderIcon(index)}
          </a>
        ))}
      </div>
      <h2 className="hireme">
        <Link to="/contact">Hire me!</Link>
      </h2>
    </>
  )
}
