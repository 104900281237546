import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      minimalistConfig(id: { eq: "site-config" }) {
        headline
        subHeadline
      }
    }
  `)

  return (
    <>
      <h1 className="headline">{data.minimalistConfig.headline}</h1>
      <p
        className="underline"
        dangerouslySetInnerHTML={{ __html: data.minimalistConfig.subHeadline }}
      ></p>
    </>
  )
}
